import { useContext } from "react";

import { Context } from "../stores/store";
import mixpanel from "../utilities/MixPanel";

const PageFooter = ({ onShowDialog }) => {
  const [ContextState] = useContext(Context);

  return (
    <div className="footer">
      {ContextState.theme === "dark" && (
        <img
          className="logo"
          src="/images/UnoSingularBrand.png"
          alt="uno logo"
        />
      )}
      {ContextState.theme !== "dark" && (
        <img
          className="logo"
          src="/images/UnoSingularBrandLight.png"
          alt="uno logo"
        />
      )}
      <div className="bottom-nav-menu">
        <a
          className="nav-item"
          href="https://resources.overlays.uno"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click How uno works")}
        >
          How uno works
        </a>

        {/*
        <a
          className="nav-item"
          href="https://resources.overlays.uno/pricing"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click Pricing")}
        >
          Pricing
        </a>
        */}

        <a
          className="nav-item"
          href="https://resources.overlays.uno/streamdeck"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click Stream Deck Plugin")}
        >
          Stream Deck Plugin
        </a>

        <a
          className="nav-item"
          href="https://resources.overlays.uno/blog"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click Blog")}
        >
          Blog
        </a>

        <a
          className="nav-item"
          href="https://merch.uno/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click Uno Merch")}
        >
          Merch
        </a>

        <div className="nav-item" onClick={() => onShowDialog("sendfeedback")}>
          Send Feedback
        </div>

        <a
          className="nav-item buy-me-a-coffee"
          href="https://www.buymeacoffee.com/overlays.uno"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(_) => mixpanel.track("Click Buy us a coffee")}
        >
          {ContextState.theme === "dark" && (
            <img src="/images/BuyMeCoffeeDark.svg" alt="Donate logo" />
          )}
          {ContextState.theme !== "dark" && (
            <img src="/images/BuyMeCoffeeLight.svg" alt="Donate logo" />
          )}
          <span className="coffeeButtonText">Donate</span>
        </a>
      </div>
      <div className="copyright">
        <div className="socials">
          <a
            className="nav-item"
            href="https://discord.gg/g6rJYsXua9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/LogoDiscord.png" alt="Discord" />
          </a>

          <a
            className="nav-item"
            href="https://www.youtube.com/@uno-overlays"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/LogoYouTube.png" alt="YouTube" />
          </a>

          <a
            className="nav-item"
            href="https://winners.webbyawards.com/2023/apps-software/software-services-platforms/creative-production/247001/uno-overlays-for-live-streaming"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/LogoWebbyHonoreeBadge.png"
              alt="Webby honoree badge"
            />
          </a>
        </div>

        <div className="rights">© 2023 All Rights Reserved</div>
        <div className="legal">
          <a
            className="nav-item"
            href="https://resources.overlays.uno/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          <div className="separator">|</div>
          <a
            className="nav-item"
            href="https://resources.overlays.uno/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
          <a
            className="nav-item"
            href="https://overlays.uno/filters"
            style={{ opacity: 0.05 }}
          >
            .
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
